@import url('https://fonts.googleapis.com/css2?family=Lato:wght@200;300;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navigation-text-light {
    @apply text-xl font-normal text-dark-1;
  }
  .navigation-text-dark {
    @apply text-xl font-normal text-light-1;
  }
  .menu-item {
    @apply navigation-text-light
    hover:navigation-text-dark hover:bg-dark-1
    dark:navigation-text-dark
    dark:hover:navigation-text-light dark:hover:bg-light-1;
  }
  .menu-collapse-button {@apply menu-item}

  .dark-mode-toggle {
    @apply dark-mode-toggle-light
    hover:dark-mode-toggle-dark
    dark:dark-mode-toggle-dark
    dark:hover:dark-mode-toggle-light;
  }
  .dark-mode-toggle-dark {
    @apply bg-dark-1 text-light-1 border-light-1;
  }
  .dark-mode-toggle-light {
    @apply bg-light-1 text-dark-1 border-dark-1;
  }

  .store-button {
    @apply store-button-light
    hover:store-button-dark
    dark:store-button-dark
    dark:hover:store-button-light;
  }
  .store-button-dark {
    @apply bg-light-2 text-dark-2;
  }
  .store-button-light {
    @apply bg-dark-2 text-light-2;
  }
}